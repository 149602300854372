import { __decorate } from "tslib";
import { Component, Vue, Ref, Watch, } from 'vue-property-decorator';
import { OperateType, AuthType } from '@/typings/app-auth';
import { AppAuthService } from '@/sevices';
import Perm from '@/router/permission';
import { CTable } from '@cloudpivot-hermes/common-components';
let SystemManager = class SystemManager extends Vue {
    constructor() {
        super(...arguments);
        this.isShowAddAuth = false;
        this.permGroupId = '';
        this.operateType = OperateType.Add;
        this.authTypes = {
            [AuthType.Org]: '按组织',
            [AuthType.User]: '按人员',
            [AuthType.Role]: '按标签',
        };
        /**
         * table列定义
         */
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                slots: { title: 'indexTitle', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                width: 250,
                dataIndex: 'name',
                key: 'name',
                ellipsis: true,
                slots: { title: 'nameTitle', name: '角色名称' },
            },
            {
                width: 250,
                dataIndex: 'authTypeName',
                key: 'authTypeName',
                ellipsis: true,
                slots: { title: 'authTypeNameTitle', name: '授权方式' },
            },
            {
                dataIndex: 'authScope',
                key: 'authScope',
                ellipsis: true,
                slots: { title: 'authScopeTitle', name: '授权范围' },
            },
            {
                width: 180,
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                slots: { title: 'actionTitle', name: '操作' },
                scopedSlots: { customRender: 'action' },
            },
        ];
        this.searchParams = {};
        this.hasCodePerm = function () { };
    }
    get appCode() {
        return this.$route.params.appCode;
    }
    get appVersion() {
        return this.$route.params.appVersion;
    }
    get appName() {
        return this.$route.query.appName;
    }
    get hasRoleManageEditPerm() {
        return this.hasCodePerm(this.$PermCode.appManage.roleManageEdit);
    }
    get hasAddRolePerm() {
        return this.hasCodePerm(this.$PermCode.appManage.addRole);
    }
    get hasDelRolePerm() {
        return this.hasCodePerm(this.$PermCode.appManage.delRole);
    }
    onKeywordChange() {
        this.searchParams.name = this.searchParams.name?.replace(/^\s*|\s*$/g, '');
    }
    created() {
        this.hasCodePerm = Perm.hasAppCodePerm(this.appCode);
    }
    /**
     * 搜索
     */
    search(searchKey) {
        const params = {
            name: searchKey,
        };
        this.searchParams = params;
        this.cTable.pagination.current = 1;
        this.cTable.getTableList();
    }
    reload() {
        this.cTable.getTableList();
    }
    /**
     * 获取列表数据接口
     */
    async getList(params) {
        const searchParams = {
            ...params,
            ...this.searchParams,
            appCode: this.appCode,
            page: params.page - 1,
        };
        const res = await AppAuthService.getAuthList(searchParams);
        if (res.success) {
            const data = res.data || [];
            data.forEach((item) => {
                item.authTypeName = this.authTypes[item.authType];
                this.$set(item, 'visibleOperateBtn', false);
                const authScope = [];
                if (Array.isArray(item.subjectDtoList)) {
                    item.subjectDtoList.forEach((scope) => {
                        authScope.push(scope.name);
                    });
                }
                if (authScope.length === 10) {
                    authScope.push('...');
                }
                item.authScope = authScope.join(',');
            });
        }
        return res;
    }
    editHandler(item) {
        this.permGroupId = item.permGroupId;
        this.operateType = OperateType.Edit;
        this.showAddAuth();
    }
    addHandler() {
        this.operateType = OperateType.Add;
        this.showAddAuth();
    }
    showAddAuth() {
        this.isShowAddAuth = true;
    }
    goPage(item = {}) {
        this.$router.push({
            name: 'auth-config-set',
            params: {
                appCode: this.appCode,
                appVersion: this.appVersion,
            },
            query: {
                permGroupId: item.permGroupId,
                authType: item.authType,
                authName: item.name,
                appName: this.appName,
            },
        });
    }
    del(item) {
        const okOk = () => {
            const params = { appCode: this.appCode, permGroupId: item.permGroupId };
            AppAuthService.deleteAuthGroup(params).then(() => {
                this.$message.success('删除成功！');
                this.reload();
            });
        };
        item.visibleOperateBtn = false;
        this.$confirm({
            title: '确定要删除这条信息吗？',
            content: `删除“${item.name}”后，该标签组的数据将被全部删除且无法恢复，确认删除吗？`,
            okText: '确定',
            cancelText: '取消',
            onOk: okOk,
        });
    }
};
__decorate([
    Ref()
], SystemManager.prototype, "cTable", void 0);
__decorate([
    Watch('searchParams', { deep: true })
], SystemManager.prototype, "onKeywordChange", null);
SystemManager = __decorate([
    Component({
        name: 'SystemManager',
        components: {
            CTable,
            AddRole: () => import('@/components/app-manage/add-role/index.vue'),
        },
    })
], SystemManager);
export default SystemManager;
